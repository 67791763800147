import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import Seo from './seo';

const StoryCard = ({ story: { title, heroImage, author, publishedDate, shortBio, pdf } }) => (
    <article className="col-md-6 my-3" data-sal="zoom-in" data-sal-delay="150" data-sal-easing="ease-in">
        <Seo
            title={title}
            description={shortBio.shortBio}
            keywords={[ 'الصحه العامه', 'الصحه النفسيه', 'شغبطه بقلم د.هبه اللكاوي', 'shaghbatta', 'قصص قصيره' ]}
        />
        <div className="container rounded h-100" style={{ backgroundColor: 'rgba(255,255,255, 0.8)' }}>
            <div className="row align-items-center">
                <div className="col-md-9 info d-flex flex-wrap align-items-center">
                    <GatsbyImage image={heroImage.gatsbyImageData} className="img-fluid rounded" alt={title} />
                    <div className="text-left pr-2">
                        <h5>{title}</h5>

                        <p className="mb-3 date">
                            <span>by {author.name}</span> / <span>{publishedDate}</span>
                        </p>
                    </div>
                </div>
                <div className="col-md-3 text-center">
                    <a className="link" href={pdf.file.url} download>
                        <FaCloudDownloadAlt /> PDF
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 info">
                    <p className="mb-0">{shortBio.shortBio}</p>
                </div>
            </div>
        </div>
    </article>
);
export default StoryCard;
