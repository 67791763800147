import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import get from 'lodash/get';
import React, { Component } from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import ArticlePreview from '../components/article-preview';
import Layout from '../components/layout';
import Seo from '../components/seo';
import InstagramPage from '../components/social/instagram-page';
import FacebookPage from '../components/social/facebook-page';
import Story from '../components/story-card';
class Root extends Component {
    render() {
        const posts = get(this, 'props.data.allContentfulBlogPost.edges');
        const stories = get(this, 'props.data.stories.edges');
        const author = get(this, 'props.data.author');
        const feeds = get(this, 'props.data.feeds');
        const services = get(this, 'props.data.services.edges');
        const site = get(this, 'props.data.site.siteMetadata');
        return (
            <Layout location={this.props.location}>
                <div className="iframe-container">
                    <iframe
                        src="https://www.youtube-nocookie.com/embed/5OiIdgw-ZAI?autoplay=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&controls=0"
                        width="100%"
                        height="100%"
                        title={`عن شغبطه`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                    />
                </div>
                <div className="container mb-5">
                    {/* services */}
                    <h2 className="latest-heading py-5 m-0 text-center">
                        <span>خدماتنا</span>
                    </h2>
                    <section className="row align-items-center container-white p-5">
                        {services.map(({ node }) => {
                            return (
                                <section
                                    className="col-md-3 my-4 text-center services"
                                    key={node.id}
                                    data-sal="zoom-in"
                                    data-sal-delay="150"
                                    data-sal-easing="ease-in">
                                    <GatsbyImage
                                        image={node.icon.gatsbyImageData}
                                        className="img-fluid"
                                        alt={node.name}
                                    />
                                    <p className="text-center my-3">{node.name}</p>
                                </section>
                            );
                        })}
                        <div className="col-md-12 mt-4 text-center">
                            <Link to={'/about/'} className="link">
                                أقرأ المزيد
                            </Link>
                        </div>
                    </section>

                    {/* latest blog posts */}

                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="latest-heading pt-5 pb-4   text-right">
                                <span>الكتابات الأخيرة</span>
                            </h2>
                            <div className="container">
                                <div className="row">
                                    {posts.map(({ node }) => {
                                        return (
                                            <article
                                                className="col-md-6 my-3 home-articles"
                                                key={node.slug}
                                                data-sal="zoom-in"
                                                data-sal-delay="150"
                                                data-sal-easing="ease-in">
                                                <ArticlePreview article={node} />
                                            </article>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <FacebookPage link={'https://www.facebook.com/ShaghbattaPsychiatrist'} />
                            <h2 className="latest-heading py-5 m-0 text-right">
                                <span>إذاعة شغبطه</span>
                            </h2>
                            <div
                                title={` شغبطه`}
                                data-sal="slide-in"
                                data-sal-delay="150"
                                data-sal-easing="ease-in"
                                dangerouslySetInnerHTML={{
                                    __html: feeds.soundCloudTrack.childMarkdownRemark.html,
                                }}
                            />
                            {/* <InstagramPage link={`https://www.instagram.com/p/CWTc5o3IkTb/`} /> */}
                        </div>
                    </div>

                    {/* author */}
                    <h2 className="latest-heading py-5 m-0 text-center">
                        <span>عن المؤسس</span>
                    </h2>
                    <div className="row justify-content-around align-items-center container-white p-4">
                        <section className="col-md-4 my-4 team-container">
                            <div
                                className="text-center team m-auto"
                                data-sal="slide-in"
                                data-sal-delay="150"
                                data-sal-easing="ease-in">
                                <GatsbyImage
                                    image={author.image.gatsbyImageData}
                                    className="rounded img-fluid"
                                    alt={author.name}
                                />
                            </div>
                        </section>
                        <section className="col-md-8 my-4 px-4 team-container">
                            <h3 className="my-4">{author.name}</h3>
                            <p className="my-3" data-sal="slide-in" data-sal-delay="150" data-sal-easing="ease-in">
                                {author.title}
                            </p>
                            <div
                                data-sal="slide-in"
                                data-sal-delay="150"
                                data-sal-easing="ease-in"
                                dangerouslySetInnerHTML={{
                                    __html: author.shortBio.childMarkdownRemark.html,
                                }}
                            />
                            <div className="text-left d-flex justify-content-between align-items-center mt-5">
                                <ul className="social d-inline-block">
                                    <li>
                                        <a
                                            className="facebook"
                                            href={author.facebook}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <FaFacebookSquare />
                                        </a>
                                    </li>
                                </ul>
                                <Link to={'/author/'} className="link">
                                    أقرأ المزيد
                                </Link>
                            </div>
                        </section>
                    </div>
                    {/* short stories  */}

                    <h2 className="latest-heading pt-5 mb-4 text-center">
                        <span>القصص القصيرة</span>
                    </h2>
                    <p className="text-dark text-center mb-5">لقراءة القصة قم بتحميل ملف ال PDF</p>
                    <div className="row justify-content-around">
                        {stories.map(({ node }) => {
                            return (
                                <Story
                                    story={node}
                                    key={node.title}
                                    data-sal="zoom-in"
                                    data-sal-delay="150"
                                    data-sal-easing="ease-in"
                                />
                            );
                        })}
                        <div className="col-md-12 mt-4 text-center">
                            <Link to={'/stories/'} className="link">
                                أقرأ المزيد
                            </Link>
                        </div>
                    </div>
                </div>
                <Seo
                    title={site.title}
                    description={site.description}
                    keywords={[ 'الصحه العامه', 'الصحه النفسيه', 'شغبطه بقلم د.هبه اللكاوي', 'shaghbatta' ]}
                />
            </Layout>
        );
    }
}
export default Root;

export const pageQuery = graphql`
    query HomeQuery {
        allContentfulBlogPost(
            sort: { fields: [publishDate], order: DESC }
            filter: { featured: { eq: true } }
            limit: 8
        ) {
            edges {
                node {
                    title
                    slug
                    category
                    publishDate(formatString: "MMMM Do, YYYY")
                    description {
                        description
                    }
                    author {
                        name
                    }
                    heroImage {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
        author: contentfulPerson(contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" }) {
            title
            name
            who
            shortBio {
                childMarkdownRemark {
                    html
                }
            }
            image {
                gatsbyImageData(layout: FULL_WIDTH)
            }
            facebook
        }
        stories: allContentfulStory(sort: { fields: [publishedDate], order: DESC }, limit: 2) {
            edges {
                node {
                    title
                    publishedDate(formatString: "MMMM Do, YYYY")
                    author {
                        name
                    }
                    shortBio {
                        shortBio
                    }
                    pdf {
                        file {
                            url
                            fileName
                            contentType
                        }
                    }
                    heroImage {
                        gatsbyImageData(layout: FIXED, width: 80)
                    }
                }
            }
        }
        feeds: contentfulAbout {
            soundCloudTrack {
                childMarkdownRemark {
                    html
                }
            }
            instagramFeed
        }
        services: allContentfulService(limit: 4) {
            edges {
                node {
                    id
                    name
                    icon {
                        gatsbyImageData(layout: FIXED, width: 80)
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;
