import React from 'react';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { GatsbyImage } from 'gatsby-plugin-image';

const ArticlePreview = ({ article: { title, heroImage, slug, category, publishDate, description, author } }) => (
    <article className="card">
        <div className="image">
            <GatsbyImage image={heroImage.gatsbyImageData} alt={title} />
        </div>
        <div className="info d-flex justify-content-between flex-column flex-fill">
            <div>
                <h3>{title}</h3>
                <p className="mb-3 date">
                    <span>by {author.name}</span> / <span>{publishDate}</span>
                </p>
                <p className={category === 'english-articles' ? 'text-left' : 'text-right'}>
                    {description.description}
                </p>
            </div>
            <Link to={'/articles/' + category + '/' + kebabCase(slug)} className="link align-self-center">
                {category === 'english-articles' ? 'Read More' : 'أقرأ المزيد'}
            </Link>
        </div>
    </article>
);
export default ArticlePreview;
